import './App.css'
import Container from './components/container/Container'

function App() {
  return (
    <div className='app'>
      <Container />
    </div>
  )
}

export default App