import {
    SiXcode,
    SiAndroidstudio,
    SiMysql,
    SiMongodb,
    SiGithub,
    SiWindowsterminal,
    SiVisualstudiocode,
    SiEclipseide,
    SiAmazonaws,
    SiAdobe,
    SiAutodesk,
    SiFigma,
    SiApple,
    SiWindows
} from 'react-icons/si'
import {
    BsDashLg
} from 'react-icons/bs'
import './About.css'

function About() {
    return (
        <div id='about' className='abtContainer'>
            <p className='abtSubtitle'>Sobre mí</p>
            <p className='abtText'>
                Me apasiona el diseño, las experiencias y los lenguajes.
                <br/>
                <br/>
                He trabajado durante 8 años en distintas áreas relacionadas con la experiencia de usuario, poniendo en práctica mis habilidades para el diseño, desarrollo y marketing.
                Los lenguajes han sido una herramienta fundamental en mi carrera, al permitirme poder conectar con el usuario y diseñar una experiencia personalizada. 
                Mis habilidades de comunicación estratégica incluyen competencias de Copywrite y UX Writing.
                <br/>
                <br/>
                Adaptabilidad, iniciativa, metodología y trabajo en equipo forman parte de mi sello profesional.
            </p>
            <br/>
            <p className='abtSubtitle'>Entornos de Diseño y Desarrollo</p>
            <div className='ablBox'>
                <div className='ablContainer'>
                    <SiApple className='abtIcon'/>
                    <p className='ablText'>macOS</p>
                </div>
                <div className='ablContainer'>
                    <SiWindows className='abtIcon'/>
                    <p className='ablText'>Windows</p>
                </div>
                <div className='ablContainer'>
                    <SiAmazonaws className='abtIcon'/>
                    <p className='ablText'>AWS</p>
                </div>
                <div className='ablContainer'>
                    <SiWindowsterminal className='abtIcon'/>
                    <p className='ablText'>Terminal</p>
                </div>
                <div className='ablContainer'>
                    <SiVisualstudiocode className='abtIcon'/>
                    <p className='ablText'>VSCode</p>
                </div>
                <div className='ablContainer'>
                    <SiEclipseide className='abtIcon'/>
                    <p className='ablText'>Eclipse</p>
                </div>
                <div className='ablContainer'>
                    <SiGithub className='abtIcon'/>
                    <p className='ablText'>GitHub</p>
                </div>
                <div className='ablContainer'>
                    <SiXcode className='abtIcon'/>
                    <p className='ablText'>Xcode</p>
                </div>
                <div className='ablContainer'>
                    <SiAndroidstudio className='abtIcon'/>
                    <p className='ablText'>Android Studio</p>
                </div>
                <div className='ablContainer'>
                    <SiMysql className='abtIcon'/>
                    <p className='ablText'>MySQL</p>
                </div>
                <div className='ablContainer'>
                    <SiMongodb className='abtIcon'/>
                    <p className='ablText'>MongoDB</p>
                </div>
                <div className='ablContainer'>
                    <SiAdobe className='abtIcon'/>
                    <p className='ablText'>Adobe</p>
                </div>
                <div className='ablContainer'>
                    <SiAutodesk className='abtIcon'/>
                    <p className='ablText'>Autodesk</p>
                </div>
                <div className='ablContainer'>
                    <SiFigma className='abtIcon'/>
                    <p className='ablText'>Figma</p>
                </div>
            </div>
            <br/>
            <p id='experience' className='abtSubtitle'>Experiencia</p>
            <div className='edEvent'>
                <p className='edDate'>2019 <BsDashLg className='abtIcon'/> 2023</p>
                <div className='edDetail'>
                    <p className='edTitle'>BANCO SANTANDER CHILE</p>
                    <p className='edText'>Experiencia de usuario</p>
                    <p className='edText'>Estrategia comercial</p>
                </div>
            </div>
            <div className='edEvent'>
                <p className='edDate'>2017 <BsDashLg className='abtIcon'/> 2019</p>
                <div className='edDetail'>
                    <p className='edTitle'>SPACE PLANNING INTERNATIONAL</p>
                    <p className='edText'>Diseño y desarrollo del sitio web del estudio de arquitectura</p>
                    <p className='edText'>Departamento creativo: ilustración y administración de contenido digital</p>
                    <p className='edText'>Renderizado de imágenes 3D y video, creación y edición de propuestas</p>
                </div>
            </div>
            <div className='edEvent'>
                <p className='edDate'>2016 <BsDashLg className='abtIcon'/> 2017</p>
                <div className='edDetail'>
                    <p className='edTitle'>DUOC UC</p>
                    <p className='edText'>Ayudantía en curso de Geometría Descriptiva</p>
                </div>
            </div>
            <br/>
            <p id='education' className='abtSubtitle'>Educación</p>
            <div className='edEvent'>
                <p className='edDate'>2024 <BsDashLg className='abtIcon'/> 2024</p>
                <div className='edDetail'>
                    <p className='edTitle'>AMAZON WEB SERVICES</p>
                    <p className='edText'>AWS Cloud Technical Essentials</p>
                </div>
            </div>
            <div className='edEvent'>
                <p className='edDate'>2023 <BsDashLg className='abtIcon'/> 2024</p>
                <div className='edDetail'>
                    <p className='edTitle'>CODERHOUSE</p>
                    <p className='edText'>Desarrollo Web Full Stack (MERN)</p>
                    <p className='edText'>Desarrollo de Aplicaciones Móviles (React Native)</p>
                    <p className='edText'>Diseño UX - UI</p>
                    <p className='edText'>Copywrite</p>
                </div>
            </div>
            <div className='edEvent'>
                <p className='edDate'>2023 <BsDashLg className='abtIcon'/> 2023</p>
                <div className='edDetail'>
                    <p className='edTitle'>UNIVERSIDAD EUROPEA DE MADRID</p>
                    <p className='edText'>Certificación Internacional en Comunicación Estratégica (Marketing Digital, SEO, SEM)</p>
                </div>
            </div>
            <div className='edEvent'>
                <p className='edDate'>2021 <BsDashLg className='abtIcon'/> 2023</p>
                <div className='edDetail'>
                    <p className='edTitle'>UNIVERSIDAD NACIONAL ANDRÉS BELLO</p>
                    <p className='edText'>Ingeniería en Administración de Empresas</p>
                </div>
            </div>
            <div className='edEvent'>
                <p className='edDate'>2018 <BsDashLg className='abtIcon'/> 2018</p>
                <div className='edDetail'>
                    <p className='edTitle'>DGM ACADEMIA</p>
                    <p className='edText'>Certificación Avanzada: Creación, edición y retoque digital con Adobe Photoshop</p>
                </div>
            </div>
            <div className='edEvent'>
                <p className='edDate'>2015 <BsDashLg className='abtIcon'/> 2017</p>
                <div className='edDetail'>
                    <p className='edTitle'>DUOC UC</p>
                    <p className='edText'>Diseño Industrial (Design Thinking, Diseño Centrado en el Usuario)</p>
                </div>
            </div>
            <br/>
        </div>
    )
}

export default About