import './Container.css'

import Navigation from '../navigation/Navigation'
import About from '../about/About'
import Projects from '../projects/Projects'

function Container() {
    return (
        <div className='container'>
            <div className='navDistribution'>
                <Navigation />
            </div>
            <div className='contDistribution'>
                <About />
                <Projects />
            </div>
        </div>
    )
}

export default Container