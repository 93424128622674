import { BsArrowUpRightSquareFill } from 'react-icons/bs'
import './Projects.css'

function Projects() {
    return (
        <div id='projects' className='prjContainer'>
            <p className='prjSubtitle'>Proyectos</p>

            {/* PROJECT #1 */}

            <a
                href='https://github.com/jp-quevedo/react-project-coderhouse'
                className='prjEvent'
                target='_blank'
                rel='noreferrer'
            >
                <div className='prjResume'>
                    <div className='imgShadow'>
                        <img className='prjThumbnail' src='https://i.imgur.com/0bAixPG.png' alt='thumbnail'/>
                    </div>
                    <div className='tagContainer'>
                        <p className='tag'>HTML5</p>
                        <p className='tag'>CSS3</p>
                        <p className='tag'>React</p>
                        <p className='tag'>API's</p>
                        <p className='tag'>Firebase</p>
                    </div>
                </div>
                <div className='prjDetail'>
                    <p className='prjTitle'>Clever Décor <BsArrowUpRightSquareFill className='prjIcon'/></p>
                    <p className='prjText'>
                        Full Responsive SPA escrita con React y Firebase:<br/><br/>
                        Transpilling<br/>
                        Components<br/>
                        Hooks<br/>
                        Promises<br/>
                        Asyncrony<br/>
                        MAP<br/>
                        API Comsumption<br/>
                        Routing<br/>
                        Events<br/>
                        Context<br/><br/>
                        Librerías utilizadas: Sweet Alert 2<br/>
                        Estilos con CSS plano
                    </p>
                </div>
            </a><br/>

            {/* PROJECT #2 */}
            <br/>
            <a
                href='https://github.com/jp-quevedo/siibot'
                className='prjEvent'
                target='_blank'
                rel='noreferrer'
            >
                <div className='prjResume'>
                    <div className='imgShadow'>
                        <img className='prjThumbnail' src='https://i.imgur.com/YNObFbY.png' alt='thumbnail'/>
                    </div>
                    <div className='tagContainer'>
                        <p className='tag'>React Native</p>
                        <p className='tag'>Node.js</p>
                        <p className='tag'>Expo CLI</p>
                        <p className='tag'>CSS3</p>
                        <p className='tag'>Firebase</p>
                        <p className='tag'>SQLite</p>
                        <p className='tag'>Emuladores</p>
                    </div>
                </div>
                <div className='prjDetail'>
                    <p className='prjTitle'>SiiBot <BsArrowUpRightSquareFill className='prjIcon'/></p>
                    <p className='prjText'>
                        Aplicación híbrida-bridge en desarrollo:<br/><br/>
                        Core Components<br/>
                        Eventos<br/>
                        Listas optimizadas<br/>
                        Split Components<br/>
                        Estilos por props y constantes<br/>
                        Switch entre pantallas<br/>
                        Fonts y elementos externos<br/>
                        Responsividad<br/>
                        Navegación Stack y Tab<br/>
                        Hooks: State, Effect, Ref, Redux<br/>
                        Firebase<br/>
                        Login y autenticación<br/>
                        Device features: camera, location<br/>
                        SQLite<br/><br/>
                        Estilos con CSS plano
                    </p>
                </div>
            </a><br/>

            {/* PROJECT #3 */}
            <br/>
            <a
                href='https://github.com/jp-quevedo/backend-project'
                className='prjEvent'
                target='_blank'
                rel='noreferrer'
            >
                <div className='prjResume'>
                    <div className='imgShadow'>
                        <img className='prjThumbnail' src='https://i.imgur.com/B8gzlEV.png' alt='thumbnail'/>
                    </div>
                    <div className='tagContainer'>
                        <p className='tag'>HTML5</p>
                        <p className='tag'>JavaScript</p>
                        <p className='tag'>Node.js</p>
                        <p className='tag'>Express</p>
                        <p className='tag'>MongoDB</p>
                        <p className='tag'>Handlebars</p>
                        <p className='tag'>Socket.io</p>
                        <p className='tag'>Nodemailer</p>
                        <p className='tag'>Faker</p>
                        <p className='tag'>Winston</p>
                        <p className='tag'>Swagger</p>
                        <p className='tag'>Bcrypt</p>
                        <p className='tag'>API's</p>
                    </div>
                </div>
                <div className='prjDetail'>
                    <p className='prjTitle'>Backend Project <BsArrowUpRightSquareFill className='prjIcon'/></p>
                    <p className='prjText'>
                        Servidor web con funcionalidades complejas:<br/><br/>
                        Administrador de Paquetes npm<br/>
                        Sincronía y Asincronía<br/>
                        Express<br/>
                        Router, Multer, motores de plantilla<br/>
                        Websockets<br/>
                        Live chat<br/>
                        MongoDB y Mongoose<br/>
                        Cockies, Sessions, Storages<br/>
                        Passport local y de terceros, Bcrypt<br/>
                        Arquitectura por capas<br/>
                        Mailing y mensajería<br/>
                        Testing mocks, testing unitario y avanzado<br/>
                        Logging y performance<br/>
                        Clusters y escalabilidad<br/>
                        Seguridad<br/>
                        Documentación de API<br/>
                        Product Cloud<br/>
                        Pasarelas de pago<br/><br/>
                        Librerías utilizadas: Toastify y Sweet Alert 2<br/>
                    </p>
                </div>
            </a>
        </div>
    )
}

export default Projects