import {
    BsDashLg,
    BsBehance,
    BsGithub,
    BsLinkedin,
    BsArrowUpRightSquareFill
} from 'react-icons/bs'
import './Navigation.css'

function Navigation() {
    return (
        <div id='#nav' className='navContainer'>
            <p className='navTitle'>Juan Pablo Quevedo</p>
            <p className='navSubtitle'>Design <BsDashLg className='navTitleIcon'/> Development</p>
            <a href='#about'><p className='navRedirect'><BsDashLg className='navLinkIcon'/>SOBRE MÍ</p></a>
            <a href='#experience'><p className='navRedirect'><BsDashLg className='navLinkIcon'/>EXPERIENCIA</p></a>
            <a href='#education'><p className='navRedirect'><BsDashLg className='navLinkIcon'/>EDUCACIÓN</p></a>
            {/* <a href='#abilities'><p className='navRedirect'><BsDashLg className='navLinkIcon'/>HABILIDADES</p></a> */}
            <a href='#projects'><p className='navRedirect'><BsDashLg className='navLinkIcon'/>PROYECTOS</p></a>
            <div className='navLinkContainer'>
                <a
                    className='navLink'
                    href='http://www.behance.net/jp-quevedo'
                    target='_blank'
                    rel='noreferrer'
                >
                    <BsBehance className='navLinkIcon'/>
                    <p>Behance</p>
                </a>
                <a
                    className='navLink'
                    href='https://github.com/jp-quevedo'
                    target='_blank'
                    rel='noreferrer'
                >
                    <BsGithub className='navLinkIcon'/>
                    <p>GitHub</p>
                </a>
                <a
                    className='navLink'
                    href='https://www.linkedin.com/in/jp-quevedo/'
                    target='_blank'
                    rel='noreferrer'
                >
                    <BsLinkedin className='navLinkIcon'/>
                    <p>LinkedIn</p>
                </a>
                <a
                    className='navLink'
                    href='/quevedo-esp-res.pdf'
                    target='_blank'
                    rel='noreferrer'
                >
                    <BsArrowUpRightSquareFill className='navLinkIcon'/>
                    <p>CV</p>
                </a>
            </div>
        </div>
    )
}

export default Navigation